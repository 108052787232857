@media (min-width: 1800px){
.shell-wide {
    max-width: 1800px;
}
}
.activity_isotope_tile_image,
.school_isotope_tile_image {
    width: 100%;
    padding-top: 65%;
    background-size: cover;
    background-position: center;
    }
.p-b-0{
    padding-bottom: 0px;
}

.p-t-0{
    padding-top:0px;
}

@media (min-width: 768px){
    .rd-google-map__model {
        height:250px;
    }
}

.m-b-10{
    margin-bottom: 10px !important;
}

.m-b-30{
    margin-bottom: 30px !important;
}

.m-t-10{
    margin-top: 10px !important;
}

.m-t-30{
    margin-top: 30px !important;
}

.breadcrumb-classic{
    .shell-wide{
        padding-top: 120px;
    }
}

.modal-footer{
    border-top: 0px;
}

.rd-navbar-megamenu.rd-navbar-open-right {
    overflow: hidden !important;
}

.rd-navbar ul.col-lg-3{
    position: relative;

    &:after{
        content: " ";
        display: block;
        position: absolute;
        top: -500px;
        right: 0px;
        width: 1px;
        bottom: -500px;
        background-color: #f094bd;
    }

    &:nth-child(4):after{
        background-color: transparent;
    }
}

@media (min-width: 768px) {
.thumbnail-apollo:hover div.school_isotope_tile_image,
.thumbnail-apollo:hover div.activity_isotope_tile_image {
    opacity: 0.2;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
}

.why-us{
    h5{
        min-height: 53px;
    }
    p{
        text-align: left;
    }
}

.newsletters-section{
    .newsletter-square{
        display: block;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        border: 1px solid #d9d9d9;
        margin: auto;

        display: table;

        .row{
            display: table-cell;
             vertical-align: middle;
        }
    }
}

.select2-container--open {
    z-index: 999999999;}

#instafeed{
    max-height: 300px;overflow: hidden;
    text-align: center;
}

[class ^= 'form-status'] {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    }

.select2-container--bootstrap .select2-selection--single .select2-selection__arrow b:before{
    content: "\F1D8" !important;
}

.form-status-success {
    background: #78d052;
}
.form-status-fail {
    background: #f35757;
}

.form-status-icon {
    font-size: 5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
}



@media(max-width:768px){
    .instagram-footer-link{
        text-align: center;
    }
}

@media(max-width:991px){
    .swiper-slide-section{
        .text-extra-big{
                font-size: 55px;
                line-height: 55px;
        }
    }

        .instagram-footer-link{
        text-align: center;
    }
}

@media(min-width:992px){}



@media(min-width:1200px){}

.instagram-footer-link{

}

@media(max-width:360px){
    .swiper-slide-caption{
        padding-left: 0px;
        padding-right: 0px;
    }
}


.range > .cell-lg-3 > .box-planning {
    min-height: 760px;
    position: relative;
}

.box-planning a.btn {
    position: absolute;
    bottom: 60px;
    width: 70%;
}

.partners .owl-item .item {
    min-height: 120px;
    background-position: center center;
    background-repeat: no-repeat;
}


.unit.unit-md.unit-md-horizontal {
    height: 240px;
}


.select2-container--bootstrap .select2-results__option[aria-disabled=true] {
    display: none;
}